import RenderUtils from '@wix/photography-client-lib/dist/src/utils/renderUtils';
import VIEW_MODE from 'pro-gallery/dist/es/src/common/constants/viewMode';
import FORM_FACTOR from 'pro-gallery/dist/es/src/common/constants/formFactor';
import { experimentsWrapper } from '@wix/photography-client-lib/dist/src/sdk/experimentsWrapper';

export class Utils extends RenderUtils {
  /* @ngInject */
  constructor() {
    super();
  }

  static getBaseUrl(api, viewMode) {
    if (Utils.parseViewMode(viewMode) === VIEW_MODE.PREVIEW) {
      return 'https://progallery.wix.com';
    } else {
      const baseUrl = api.location.baseUrl;
      const baseUrlParts = baseUrl.split('/');
      const origin = baseUrlParts.slice(0, 3).join('/');
      return origin;
    }
  }

  static getExperiments(baseApi) {
    // only under scope `pro-gparseViewModeallery-viewer`
    const viewMode = Utils.parseViewMode(baseApi.window.viewMode);
    const baseUrl = Utils.getBaseUrl(baseApi, viewMode);
    return fetch(`${baseUrl}/_api/pro-gallery-webapp/v1/viewer/experiments`, {
      credentials: 'include',
    })
      .then(res => res.json())
      .then(res => res.experiments)
      .catch(() => {
        return {};
      });
  }

  static parseViewMode(viewMode) {
    switch (viewMode.toLowerCase()) {
      case 'editor':
        return VIEW_MODE.EDIT;
      case 'preview':
        return VIEW_MODE.PREVIEW;
      case 'site':
      default:
        return VIEW_MODE.SITE;
    }
  }
  static parseFormFactor(formFactor) {
    switch (formFactor.toLowerCase()) {
      case 'tablet':
        return FORM_FACTOR.TABLET;
      case 'mobile':
        return FORM_FACTOR.MOBILE;
      case 'desktop':
      default:
        return FORM_FACTOR.DESKTOP;
    }
  }

  static async verifyExperiments(scopedGlobalSdkApis) {
    if (
      !experimentsWrapper.getExperiments() ||
      Object.keys(experimentsWrapper.getExperiments()).length === 0
    ) {
      const experimentsRaw = await Utils.getExperiments(scopedGlobalSdkApis);
      experimentsWrapper.setExperiments(experimentsRaw);
    }
  }
}

export const utils = new Utils();
